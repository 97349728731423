import FlyoutRenderer from 'common/visualizations/views/FlyoutRenderer';

(function($) {

  function getColumnByColumnId(view, columnId) {
    return _.find(_.get(view, 'columns', []), {id: columnId});
  }

  function getColumnByColumnFieldName(view, columnFieldName) {
    return _.find(_.get(view, 'columns', []), {fieldName: columnFieldName});
  }

  function vifOrderFromView(view) {
    var orderBys = _.get(view, 'query.orderBys');
    var vifOrder;

    if (!_.isArray(orderBys)) {
      vifOrder = null;
    } else {

      vifOrder = orderBys.
        map(function(orderParam) {
          var columnId = _.get(orderParam, 'expression.columnId');
          var column = getColumnByColumnId(view, columnId);

          return {
            ascending: orderParam.ascending,
            columnName: _.get(column, 'fieldName')
          };
        });
    }

    return vifOrder;
  }

  $.fn.socrataTable = _.get(
    window,
    'blist.Visualizations.Table',
    // Provide a stub as a default so that things don't explode if the Socrata
    // Viz table is somehow not available. This will really only ever get
    // called on a jQuery selection, and doesn't really need to do anything.
    function() {
      if (console && console.error) {
        console.error(
          'The Socrata Visualizations Table could not be instantiated ' +
          'because it is not defined.'
        );
      }
    }
  );

  $.fn.isSocrataVizDatasetGrid = function() {
    return !_.isUndefined($(this[0]).data('socrataVizDatasetGrid'));
  };

  $.fn.socrataVizDatasetGrid = function(options) {
    // Check if object was already created
    var socrataVizDatasetGrid = $(this[0]).data('socrataVizDatasetGrid');
    if (!socrataVizDatasetGrid) {
      /* eslint-disable new-cap */
      socrataVizDatasetGrid = new $.socrataVizDatasetGridObject(
        options,
        this[0]
      );
      /* eslint-enable new-cap */
    }
    return socrataVizDatasetGrid;
  };

  $.socrataVizDatasetGridObject = function(options, grid) {
    this.settings = $.extend(
      {},
      $.socrataVizDatasetGridObject.defaults,
      options
    );
    this.currentGrid = grid;
    this.init();
  };

  $.extend(
    $.socrataVizDatasetGridObject,
    {
      defaults: {
        addColumnCallback: function() {},
        columnDeleteEnabled: false,
        columnHideEnabled: true,
        columnNameEdit: false,
        columnPropertiesEnabled: false,
        editColumnCallback: function() {},
        editEnabled: true,
        manualResize: false,
        showRowHandle: false,
        showRowNumbers: true,
        showAddColumns: false,
        view: null
      },

      prototype: {
        init: function() {
          var DEFAULT_PAGE_SIZE = 100;

          var self = this;
          var currentStartIndex;
          var currentEndIndex;
          var lastRenderedVif;
          var $datasetGrid;
          var flyoutRenderer = new FlyoutRenderer();
          var configuredPageSize = _.get(window, 'socrata.gridView.rowsPerPage', null);
          var pageSize = (!_.isNull(configuredPageSize)) ? parseInt(configuredPageSize, 10) : DEFAULT_PAGE_SIZE;

          // If the only thing changing is pagination, you can just call
          // 'loadRows(startIndex, endIndex)' instead of resetting all
          // the state and rerendering everything based on the vif.
          function renderTableFromScratch() {

            currentStartIndex = 0;
            currentEndIndex = pageSize;
            lastRenderedVif = null;

            loadRows(currentStartIndex, currentEndIndex);
          }

          function loadRows(startIndex, endIndex) {

            function renderTableForViews(currentView, parentView) {
              var savedView = _.cloneDeep(_.get(self, 'view._origObj', {}));
              var tableColumnWidths = {};
              var rowLabelFromView = _.get(currentView, 'metadata.rowLabel', null);
              var rowLabelOne = null;
              var rowLabelOther = null;

              currentView.newBackend = _.get(self, 'view.newBackend', false);

              currentView.columns.forEach(function(column) {
                tableColumnWidths[column.fieldName] = column.width;
              });

              if (!_.isEmpty(rowLabelFromView)) {
                rowLabelOne = rowLabelFromView;
                // Wow, this is hacky. $.pluralize() will return something like
                // "2 rows", and we just want what it thinks is the plural form
                // of the unit we passed it. Since we control the quantity (and
                // we know it will be 2) we can just take everything past the
                // "2 " in $.pluralize()'s return value.
                rowLabelOther = $.pluralize(2, rowLabelFromView).substring(2);
              } else {
                rowLabelOne = $.t('core.default_row_label_one');
                rowLabelOther = $.t('core.default_row_label_other');
              }

              var newVifToRender = {
                configuration: {
                  order: vifOrderFromView(currentView),
                  tableColumnWidths: tableColumnWidths,
                  viewSourceDataLink: false
                },
                format: {
                  type: 'visualization_interchange_format',
                  version: 3
                },
                series: [
                  {
                    dataSource: {
                      datasetUid: self.view.id,
                      domain: window.location.hostname,
                      limit: endIndex - startIndex,
                      offset: startIndex,
                      type: 'socrata.view',
                      currentView: currentView,
                      savedView: savedView,
                      parentView: (_.isUndefined(parentView)) ? null : parentView.cleanCopyIncludingRenderTypeName()
                    },
                    label: null,
                    type: 'table',
                    unit: {
                      one: rowLabelOne,
                      other: rowLabelOther
                    }
                  }
                ],
                title: null
              };
              var renderVifEvent = new window.CustomEvent(
                'SOCRATA_VISUALIZATION_RENDER_VIF',
                {
                  detail: newVifToRender,
                  bubbles: false
                }
              );
              var $socrataVisualization = $datasetGrid.
                children('.socrata-visualization');

              if ($socrataVisualization.length === 0) {
                $datasetGrid.socrataTable(newVifToRender);
              } else {
                $datasetGrid[0].dispatchEvent(renderVifEvent);
              }
            }

            self.view.getParentView(function(parentView) {
              var currentView = self.view.cleanCopyIncludingRenderTypeName();
              // We must read the child views off of the live dataset instance because they are not
              // included in the serialized copy returned by cleanCopyIncludingRenderTypeName().
              var firstChildViewId = _.get(self, 'view.childViews[0]', null);

              // We must read the viewType off of the live dataset instance because it is not
              // included in the serialized copy returned by cleanCopyIncludingRenderTypeName().
              if ((_.get(self, 'view.viewType') === 'geo') && (!_.isNull(firstChildViewId))) {

                $.ajax({
                  url: '/api/views/' + firstChildViewId + '.json',
                  type: 'GET',
                  headers: {
                    'Content-type': 'application/json',
                    'X-App-Token': _.get(window, 'blist.configuration.appToken', '')
                  },
                  success: function(geoFirstChildView) {
                    renderTableForViews(geoFirstChildView, parentView);
                  },
                  error: function() {
                    if (console && console.error) {
                      console.error("Encountered error retrieving first child of geo view with id '" + firstChildViewId + "'.");
                    }
                    renderTableForViews(currentView, parentView);
                  }
                });
              } else {
                renderTableForViews(currentView, parentView);
              }
            });

            if (_.isFunction(_.get(blist, 'datasetPage.adjustSize'))) {
              setTimeout(
                blist.datasetPage.adjustSize,
                1000
              );
            }
          }

          function getCurrentUserColumns() {
            // Get all columns as JSON objects, not instances. Because we are pulling from
            // blist.dataset, they should be up-to-date with regard to other changes the
            // user has made (e.g. to column order), and since we get the 'fresh' version
            // every time we render the modal, we should not run into metadata drift any
            // worse than we already do on account of the Dataset model being a little
            // iffy.
            return _.get(self, 'view.columns', []).map(function(column) {
              var cleanColumn = column.cleanCopy();
              // The 'noCommas' and 'precision' properties, when present, have values that
              // are essentially booleans and numbers but represented as strings (ugh),
              // so in order to simplify the code that actually deals with these things
              // above we do those conversions here in a 'pre-processing' step. We'll
              // also have to convert these values, if present, back into strings in a
              // 'post-processing' step before we PUT to `/api/views/<viewUid>`.
              if (cleanColumn.format.hasOwnProperty('noCommas')) {
                cleanColumn.format.noCommas = (cleanColumn.format.noCommas === 'true') ?
                  true :
                  false;
              }

              if (cleanColumn.format.hasOwnProperty('precision')) {
                cleanColumn.format.precision = parseInt(cleanColumn.format.precision, 10);
              }

              return cleanColumn;
            // Remove system columns (which have an id of -1)
            }).filter(function(column) {
              return column.id >= 0;
            // Sort by position
            }).sort(function(a, b) {
              var aPosition = _.get(a, 'position', -1);
              var bPosition = _.get(b, 'position', -1);

              return (aPosition <= bPosition) ? -1 : 1;
            });
          }

          function attachTableEventHandlers() {

            function updateViewWithColumnWidthsFromVif(tableColumnWidths) {
              var isWorkingCopy = self.view.publicationStage === 'unpublished';

              // If derived view publication is enabled, you should only be able to
              // change column widths on the thing you're on, and only when it's a
              // working copy. You should not be able to change column widths and
              // then 'Save As' to create a new view, nor should you be prompted to
              // save column width changes on a published view.
              if (!isWorkingCopy) {
                return;
              }

              // ...but if derived view publication is not enabled, then you should
              // be able to change column widths willy nilly, because that is what
              // the legacy behavior was.
              var newView = _.cloneDeep(self.view.cleanCopy());
              newView.columns.forEach(function(column) {

                if (tableColumnWidths.hasOwnProperty(column.fieldName)) {

                  column.width = tableColumnWidths[column.fieldName];
                }
              });

              self.view.update(newView, false, false);
            }

            $datasetGrid.
              on('SOCRATA_VISUALIZATION_VIF_UPDATED', function(e) {
                var updatedVif = e.originalEvent.detail;
                var lastRenderedTableColumnWidths = _.get(
                  lastRenderedVif,
                  'configuration.tableColumnWidths',
                  null
                );
                var updatedTableColumnWidths = _.get(
                  updatedVif,
                  'configuration.tableColumnWidths',
                  null
                );
                var tableColumnWidthsEqual = _.isEqual(
                  lastRenderedTableColumnWidths,
                  updatedTableColumnWidths
                );

                if (!tableColumnWidthsEqual) {
                  updateViewWithColumnWidthsFromVif(updatedTableColumnWidths);
                }
              });

            $datasetGrid.
              on('SOCRATA_VISUALIZATION_COLUMN_CLICKED', function(e) {
                var view = self.view.cleanCopyIncludingRenderTypeName();

                // EN-29700 - Non-SoQL-data-shaping should be disabled on SoQL-based view
                //
                // We should not apply the orderBys property in the query, or respond to
                // the SOCRATA_VISUALIZATION_COLUMN_SORT_APPLIED event, when the view is
                // being defined by a SoQL query since the source of truth about the state
                // of the view is the SoQL query itself, not the UI controls.
                if (!_.isUndefined(_.get(view, 'queryString'))) {
                  return;
                }

                var columnId = _.get(getColumnByColumnFieldName(view, e.originalEvent.detail), 'id');
                var existingOrderBys = _.cloneDeep(
                  _.get(self, 'view.query.orderBys', [])
                );
                var newOrderBys;
                var newQuery = $.extend(true, {}, self.view.query);

                if (
                  existingOrderBys.length === 1 &&
                  (existingOrderBys[0].expression.columnId === columnId)
                ) {

                  newOrderBys = [{
                    ascending: !existingOrderBys[0].ascending,
                    expression: {
                      columnId: columnId,
                      type: 'column'
                    }
                  }];
                } else {

                  newOrderBys = [{
                    ascending: true,
                    expression: {
                      columnId: columnId,
                      type: 'column'
                    }
                  }];
                }

                _.set(newQuery, 'orderBys', newOrderBys);

                self.view.update({query: newQuery}, false, true);
              });

            $datasetGrid.
              on('SOCRATA_VISUALIZATION_ROW_DOUBLE_CLICKED', function(e) {
                let isDefaultTabularViewOrMetadataTable = (
                  (self.view.isDefault() && self.view.type === 'blist') || (self.view.type === 'metadata_table')
                );
                let isWorkingCopy = self.view.publicationStage === 'unpublished';
                let payload = _.get(e, 'originalEvent.detail', {});

                if (isDefaultTabularViewOrMetadataTable && isWorkingCopy) {
                  var rowEditorOptions = {
                    viewId: self.view.id,
                    columns: payload.columns,
                    row: {
                      id: payload.row.id,
                      data: payload.row.data
                    }
                  };

                  window.blist.gridViewRowEditor(rowEditorOptions);
                }
              });

            $datasetGrid.
              on('SOCRATA_VISUALIZATION_ROW_SINGLE_CLICKED', function(e) {
                let isWorkingCopy = self.view.publicationStage === 'unpublished';
                let isSingleRowViewOpen = _.get(window, 'blist.datasetPage.rtManager.visibleTypes.page', false);
                let payload = _.get(e, 'originalEvent.detail', {});

                if (!isWorkingCopy && isSingleRowViewOpen) {
                  window.blist.datasetPage.rtManager.update('page', payload);
                  var selectedTableRow = self.currentGrid.querySelector(`tr[data-row-id='${payload.row.id}']`);
                  // need to remove it from previously selected
                  if (!_.isEmpty(selectedTableRow)) {
                    var previouslySelected = self.currentGrid.querySelector('tr.single-row-selected');
                    if (!_.isEmpty(previouslySelected)) {
                      previouslySelected.classList.remove('single-row-selected');
                    }

                    selectedTableRow.classList.add('single-row-selected');
                  }
                }
              });

            $datasetGrid.
              on('SOCRATA_VISUALIZATION_COLUMN_SORT_APPLIED', function(e) {
                var view = self.view.cleanCopyIncludingRenderTypeName();

                // EN-29700 - Non-SoQL-data-shaping should be disabled on SoQL-based view
                //
                // We should not apply the orderBys property in the query, or respond to
                // the SOCRATA_VISUALIZATION_COLUMN_SORT_APPLIED event, when the view is
                // being defined by a SoQL query since the source of truth about the state
                // of the view is the SoQL query itself, not the UI controls.
                if (!_.isUndefined(_.get(view, 'queryString'))) {
                  alert($.t('controls.filter.main.view_is_soql_based_view'));
                  return;
                }

                var newOrderBys = [{
                  ascending: e.originalEvent.detail.ascending,
                  expression: {
                    columnId: _.get(getColumnByColumnFieldName(view, e.originalEvent.detail.columnName), 'id'),
                    type: 'column'
                  }
                }];
                var newQuery = $.extend(true, {}, self.view.query);

                _.set(newQuery, 'orderBys', newOrderBys);

                self.view.update({query: newQuery}, false, true);
              });

            // The table will not emit events that will take us outsize the
            // [0, totalRowCount] interval, so we can be very naive here about
            // how we respond to the ...PAGINATION_PREVIOUS and
            // ...PAGINATION_NEXT events.
            $datasetGrid.
              on('SOCRATA_VISUALIZATION_PAGINATION_PREVIOUS', function() {

                currentStartIndex -= pageSize;
                currentEndIndex -= pageSize;

                loadRows(currentStartIndex, currentEndIndex);
              });

            // See comment above handler for the ...PAGINATION_PREVIOUS event.
            $datasetGrid.
              on('SOCRATA_VISUALIZATION_PAGINATION_NEXT', function() {

                currentStartIndex += pageSize;
                currentEndIndex += pageSize;

                loadRows(currentStartIndex, currentEndIndex);
              });

            $datasetGrid.on('SOCRATA_VISUALIZATION_FLYOUT', function(e) {

                if (e.originalEvent.detail) {
                  flyoutRenderer.render(e.originalEvent.detail);
                } else {
                  flyoutRenderer.clear();
                }
              });

            $('.outerContainer').on('click', '#gridSidebar_outer_edit a.editColumn', function() {

              if ($(this).hasClass('disabled')) {
                return;
              }

              var columnEditorOptions = {
                columns: getCurrentUserColumns()
              };

              window.blist.gridViewColumnEditor(columnEditorOptions);
            });

            $('.outerContainer').on('click', '#gridSidebar_outer_edit a.addRow', function() {
                var rowEditorOptions = {
                  viewId: self.view.id,
                  columns: getCurrentUserColumns(),
                  row: {
                    id: null,
                    data: null
                  }
                };

                window.blist.gridViewRowEditor(rowEditorOptions);
            });

            $('.outerContainer').on('click', '#gridSidebar_outer_filter a.queryEditor', function() {
                window.blist.gridViewQueryEditor();
            });
          }

          /**
           * Execution starts here!
           */

          self.view = self.settings.view;
          // Override the NBE bucket size because we are now using a paginated
          // table rather than requesting 1000 rows at a time in order to make
          // the infini-scrolling table somewhat responsive.
          self.view.bucketSize = pageSize;
          self.view.bind('query_change', renderTableFromScratch);
          self.view.bind('query_string_change', renderTableFromScratch);
          self.view.bind(
            'columns_changed',
            function() {
              // EN-19727 - Editing Column Order Freezes Edit Pane
              //
              // A lot of the pane components do not handle state changes well,
              // but because of the way the panes work (every component gets
              // re-instantiated every time you close and re-open the pane) that
              // doesn't usually cause problems. In this case, however, the widget
              // that allows for column reordering (awesomereorder) is getting its
              // state messed up after you reorder columns and click apply (which
              // apparently no longer causes the sidebar to be hidden). In this case
              // the easiest and most robust solution seems to be just to hide the
              // sidebar when the user clicks apply to reorder columns (which was,
              // apparently, the behavior of the 'classic' grid view), as hiding
              // it will cause the state to be flushed and rendered once more
              // operable.
              if (_.isFunction(_.get(blist, 'datasetPage.sidebar.hide'))) {
                blist.datasetPage.sidebar.hide();
              }

              self.view.maybeEnableOrDisableDerivedViews2018Controls();

              renderTableFromScratch();
            }
          );
          // EN-21946 - Grouping and Roll-up incorrect before save.
          //
          // We use the 'row_count_change' event as a proxy for aggregation or filtering
          // having occurred, which in this context means we want to reload the data from
          // scratch, which will cause the correct grouping and roll-up results to be
          // shown before the view is saved.
          self.view.bind('row_change', renderTableFromScratch);

          $datasetGrid = $(self.currentGrid);

          renderTableFromScratch();
          attachTableEventHandlers();
        },

        isValid: function() {
          return !$.isBlank(this.view);
        }
      }
    }
  );
})(jQuery);
